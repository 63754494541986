/* for google map api */
function filterGoogleMapApiResponse(data) {
		const {
				address_components = [], formatted_address = "", name = "", geometry = {}
		} = data || {};

		const geo_info = {};

		address_components.forEach(({long_name = null, types: [type = null] = []}) => geo_info[type] = long_name);

		const {
				country = null, postal_code = null, locality = null, administrative_area_level_1 = null,
				administrative_area_level_2 = null, administrative_area_level_3 = null
		} = geo_info;

		let state = administrative_area_level_1;
		let district = administrative_area_level_2;
		let city = administrative_area_level_3 || locality;

		city = getHindiToEngCityName(city);
		state = getHindiToEngStateName(state);

		return {...geo_info,country, state, district, city, postal_code, name, formatted_address, geometry, locality};
}

function getHindiToEngStateName(stateName) {
		if (!stateName) return stateName;

		if (getAllHindiStates.hasOwnProperty(stateName)) {
				return getAllHindiStates[stateName];
		} else {
				return stateName;
		}
}

function getHindiToEngCityName(cityName) {
		if (!cityName) return cityName;

		if (getAllHindiCities.hasOwnProperty(cityName)) {
				return getAllHindiCities[cityName];
		} else {
				return cityName;
		}
}


function hasHindiCharacters(str) {
		return str.split("").filter(function (char) {
				let charCode = char.charCodeAt();
				return charCode >= 2309 && charCode <= 2361;
		}).length > 0;
}

/* /for google map api */

function filteredFormattedAddressDataSlug(searchLocationPlaces){
		if(searchLocationPlaces.length > 0) return false;
		let filteredFormattedAddressData = filterGoogleMapApiResponse(searchLocationPlaces);
		let filteredFormattedAddressDataStr = '';

		for (let filterName in filteredFormattedAddressData) {
				if (['geometry', 'postal_code', 'plus_code'].indexOf(filterName) >= 0) continue;

				if(filterName === "name"){
					if(slugify(filteredFormattedAddressData[filterName]) === 'hei-schools-sector-49-gurugram-best-preschool-and-daycare-in-sector-49-gurugram-pre-nursery-admission-in-gurgaon'){
						filteredFormattedAddressData[filterName] = "hei-schools-sector-49-gurugram";
					}
				}
				if(!!filteredFormattedAddressData[filterName]) filteredFormattedAddressDataStr += `${filterName}=${slugify(filteredFormattedAddressData[filterName])} `;
		}
		filteredFormattedAddressDataStr = filteredFormattedAddressDataStr.trim().split(' ').join('&');
		return filteredFormattedAddressDataStr;
}
